import * as React from 'react';
import { WPImageFluid } from 'shared/types/wp_localImage';
import { Picture as PictureImpl } from 'ui/components/atoms/picture/picture';
import { WYSIWYG as WYSIWYGImpl } from 'ui/components/molecules/wysiwyg/wysiwyg';
import { Container } from 'ui/components/organisms/container/container';
import { Col, Row } from 'ui/components/organisms/grid/grid';
import styles from './side_image.module.css';

type Side = 'LEFT' | 'RIGHT';

type Props = {
  wysiwyg: string;
  image: WPImageFluid;
  side: Side;
  isLast?: boolean;
};

const SideImageComponent = ({ wysiwyg, image, side, isLast }: Props) => {
  const WYSIWYG = () => (
    <div className={styles.wysiwygContainer}>
      <WYSIWYGImpl html={wysiwyg} />
    </div>
  );
  const Picture = () => (
    <div className={styles.pictureContainer}>
      <PictureImpl
        type="fluid"
        fluid={image.localFile.childImageSharp.fluid}
        placeholder="285x190"
      />
    </div>
  );
  return (
    <Container>
      <Row className={!isLast ? styles.row : undefined}>
        <Col sm={12} md={side === 'LEFT' ? 5 : 6}>
          {side === 'LEFT' ? <WYSIWYG /> : <Picture />}
        </Col>
        <Col
          sm={12}
          md={side === 'LEFT' ? 6 : 5}
          mdOffset={1}
          className={side === 'LEFT' ? styles.col : undefined}>
          {side === 'LEFT' ? <Picture /> : <WYSIWYG />}
        </Col>
      </Row>
    </Container>
  );
};

export const SideImage = React.memo(SideImageComponent);
