import * as React from 'react';
import { CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_blocks_WpPage_Acftemplatecampaign_Campaign_Blocks_Accordion } from 'templates/gql_types/CampaignPageQuery';
import { Faq } from 'ui/components/molecules/faq/faq';
import { BackgroundColor } from 'ui/components/molecules/section/section';
import { checkExists } from 'util/exists';

export const AccordionBlock = ({
  block,
  backgroundColor,
}: {
  block: CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_blocks_WpPage_Acftemplatecampaign_Campaign_Blocks_Accordion;
  backgroundColor: BackgroundColor;
}) => (
  <Faq
    backgroundColor={backgroundColor}
    preheading={checkExists(block.preheading)}
    heading={block.heading}
    questions={block.accordionItems.map(x =>
      x.__typename ===
      'WpPage_Acftemplatecampaign_Campaign_Blocks_Accordion_AccordionItems_Relational'
        ? {
            question: checkExists(x.relational.acf?.faq?.question),
            answer: checkExists(x.relational.acf?.faq?.answer),
          }
        : x,
    )}
  />
);
