import { graphql } from 'gatsby';
import { Campaign } from 'ui/components/pages/campaign/campaign';

export const query = graphql`
  query CampaignPageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      ...PageContent
      acfTemplateCampaign {
        campaign {
          pageSettings {
            alert
            navigation
            ctas {
              ... on WpPage_Acftemplatecampaign_Campaign_PageSettings_Ctas_Cta {
                href
                label
              }
            }
          }
          blocks {
            ... on WpPage_Acftemplatecampaign_Campaign_Blocks_Banner {
              heading
              description
              image {
                localFile {
                  ...FluidImageFull
                }
              }
              table {
                label
                value
              }
              cta {
                ... on WpPage_Acftemplatecampaign_Campaign_Blocks_Banner_Cta_Primarycta {
                  label
                  href
                }
                ... on WpPage_Acftemplatecampaign_Campaign_Blocks_Banner_Cta_Secondarycta {
                  label
                  href
                }
              }
            }
            ... on WpPage_Acftemplatecampaign_Campaign_Blocks_Assetblock {
              title
              asset {
                ... on WpPage_Acftemplatecampaign_Campaign_Blocks_Assetblock_Asset_Vimeo {
                  vimeoid
                  caption
                }
                ... on WpPage_Acftemplatecampaign_Campaign_Blocks_Assetblock_Asset_Image {
                  caption
                  image {
                    localFile {
                      ...FluidImageFull
                    }
                  }
                }
              }
            }
            ... on WpPage_Acftemplatecampaign_Campaign_Blocks_Wysiwygblock {
              wysiwyg
            }
            ... on WpPage_Acftemplatecampaign_Campaign_Blocks_Sideimageblock {
              preheading
              heading
              sideImages {
                wysiwyg
                image {
                  localFile {
                    ...FluidImageFull
                  }
                }
              }
            }
            ... on WpPage_Acftemplatecampaign_Campaign_Blocks_Accordion {
              preheading
              heading
              accordionItems {
                ... on WpPage_Acftemplatecampaign_Campaign_Blocks_Accordion_AccordionItems_Relational {
                  relational {
                    ... on WpFaq {
                      acf: acfFAQs {
                        faq {
                          question
                          answer
                        }
                      }
                    }
                  }
                }
                ... on WpPage_Acftemplatecampaign_Campaign_Blocks_Accordion_AccordionItems_Individual {
                  question
                  answer
                }
              }
            }
            ... on WpPage_Acftemplatecampaign_Campaign_Blocks_Grid {
              preheading
              heading
              gridItems {
                heading
                description
                subheading
                image {
                  localFile {
                    ...FluidImageFull
                  }
                }
                link
              }
              gridCta {
                ... on WpPage_Acftemplatecampaign_Campaign_Blocks_Grid_GridCta_PageLink {
                  label
                  url {
                    ... on WpPage {
                      uri
                    }
                  }
                }
                ... on WpPage_Acftemplatecampaign_Campaign_Blocks_Grid_GridCta_CustomLink {
                  label
                  urlCustom
                }
              }
            }
            ... on WpPage_Acftemplatecampaign_Campaign_Blocks_Carousel {
              carouselCaption
              carouselItems {
                image {
                  localFile {
                    ...FluidImageFull
                  }
                }
              }
            }
            ... on WpPage_Acftemplatecampaign_Campaign_Blocks_Table {
              tableheading
              tablepreheading
              tableLayout
              tableContents {
                ... on WpPage_Acftemplatecampaign_Campaign_Blocks_Table_TableContents_Tablehead {
                  thColumn1
                  thColumn2
                  thColumn3
                  thColumn4
                }
                ... on WpPage_Acftemplatecampaign_Campaign_Blocks_Table_TableContents_Tablerow {
                  tdColumn1
                  tdColumn2
                  tdColumn3
                  tdColumn4
                }
              }
              tableDisclaimer
              tableCta {
                ... on WpPage_Acftemplatecampaign_Campaign_Blocks_Table_TableCta_PageLink {
                  label
                  url {
                    ... on WpPage {
                      uri
                    }
                  }
                }
                ... on WpPage_Acftemplatecampaign_Campaign_Blocks_Table_TableCta_CustomLink {
                  urlCustom
                  label
                }
              }
            }
            ... on WpPage_Acftemplatecampaign_Campaign_Blocks_Carousel {
              carouselCaption
              carouselItems {
                image {
                  localFile {
                    ...FluidImageFull
                  }
                }
              }
            }
            ... on WpPage_Acftemplatecampaign_Campaign_Blocks_Getintouch {
              getintouchPreheading
              getintouchHeading
              getintouchDescription
              getintouchCta {
                ... on WpPage_Acftemplatecampaign_Campaign_Blocks_Getintouch_GetintouchCta_PageLink {
                  label
                  url {
                    ... on WpPage {
                      uri
                    }
                  }
                }
                ... on WpPage_Acftemplatecampaign_Campaign_Blocks_Getintouch_GetintouchCta_CustomLink {
                  label
                  urlCustom
                }
              }
            }
          }
        }
      }
    }
    wpGlobal: wp {
      ...WpGlobal
    }
  }
`;

export default Campaign;
