import * as React from 'react';
import classNames from 'classnames';
import styles from './quote.module.css';

type QuoteProps = {
  children: any;
  className?: string;
};

export const Quote = ({ children, className }: QuoteProps) => (
  <div className={classNames(styles.container, className)}>{children}</div>
);
