import classNames from 'classnames';
import * as React from 'react';
import { WPImageFluid } from 'shared/types/wp_localImage';
import {
  CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_blocks_WpPage_Acftemplatecampaign_Campaign_Blocks_Banner,
  CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_blocks_WpPage_Acftemplatecampaign_Campaign_Blocks_Banner_cta,
  CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_blocks_WpPage_Acftemplatecampaign_Campaign_Blocks_Banner_table,
  CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_pageSettings_ctas,
} from 'templates/gql_types/CampaignPageQuery';
import { PrimaryButtonLink, PrimaryInvertedButtonLink } from 'ui/components/atoms/link/link';
import { Picture } from 'ui/components/atoms/picture/picture';
import { Quote } from 'ui/components/atoms/quote/quote';
import { TextSmall, TextSub1 } from 'ui/components/atoms/typography/typography';
import { Banner } from 'ui/components/molecules/banner/banner';
import { WYSIWYG } from 'ui/components/molecules/wysiwyg/wysiwyg';
import { Col, Row } from 'ui/components/organisms/grid/grid';
import { createFluidImage } from 'util/createFluidImage';
import { exists } from 'util/exists';
import styles from './block_banner.module.css';

const AssetImage = ({ image }: { image: WPImageFluid }) => {
  return (
    <Picture type="fluid" fluid={image.localFile.childImageSharp.fluid} placeholder="285x190" />
  );
};

const getCTA = (
  cta: CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_blocks_WpPage_Acftemplatecampaign_Campaign_Blocks_Banner_cta,
) => {
  switch (cta.__typename) {
    case 'WpPage_Acftemplatecampaign_Campaign_Blocks_Banner_Cta_Primarycta':
      return () => (
        <div>
          <PrimaryButtonLink href={cta.href}>{cta.label}</PrimaryButtonLink>
        </div>
      );
    case 'WpPage_Acftemplatecampaign_Campaign_Blocks_Banner_Cta_Secondarycta':
      return () => (
        <div className={styles.secondaryCta}>
          <PrimaryInvertedButtonLink href={cta.href}>{cta.label}</PrimaryInvertedButtonLink>
        </div>
      );
  }
};

const createTable = (
  table: CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_blocks_WpPage_Acftemplatecampaign_Campaign_Blocks_Banner_table[],
): React.ComponentType => {
  if (!table.length) {
    return () => null;
  }
  const [cell1, cell2, cell3, cell4] = table;
  return () => (
    <Quote>
      <Row>
        {cell1 && (
          <Col sm={4}>
            <TextSub1>{cell1.label}</TextSub1>
            <TextSmall
              className={classNames({
                [styles.tableCellThin]: cell4 != null,
              })}
              color="navy"
              tagName="div">
              {cell1.value}
            </TextSmall>
          </Col>
        )}
        {cell2 && (
          <Col sm={4}>
            <TextSub1>{cell2.label}</TextSub1>
            <TextSmall
              className={classNames({
                [styles.tableCellThin]: cell4 != null,
              })}
              color="navy"
              tagName="div">
              {cell2.value}
            </TextSmall>
          </Col>
        )}
        {cell3 && (
          <Col sm={4}>
            <TextSub1>{cell3.label}</TextSub1>
            <TextSmall
              className={classNames({
                [styles.tableCellThin]: cell4 != null,
              })}
              color="navy"
              tagName="div">
              {cell3.value}
            </TextSmall>
          </Col>
        )}
        {cell4 && (
          <Col sm={12}>
            <TextSub1>{cell4.label}</TextSub1>
            <TextSmall color="navy" tagName="div">
              {cell4.value}
            </TextSmall>
          </Col>
        )}
      </Row>
    </Quote>
  );
};

const BannerBlockComponent = ({
  block,
  pageSettingsCTAs,
}: {
  block: CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_blocks_WpPage_Acftemplatecampaign_Campaign_Blocks_Banner;
  pageSettingsCTAs: CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_pageSettings_ctas[];
}) => {
  const { heading, image, description, cta, table } = block;
  const CTAs = cta ? cta.filter(exists).map(getCTA) : [];
  const fluidImage = createFluidImage(image);
  const Asset = () => <AssetImage image={fluidImage} />;
  const TextContent = () => <WYSIWYG html={description} />;
  const CTA = () => (
    <div className={styles.cta}>
      {CTAs.map((C, i) => (
        <C key={i} />
      ))}
    </div>
  );
  const bannerTable = table ? table.filter(exists) : [];
  const Table = createTable(bannerTable);
  const AssetCTA = () =>
    pageSettingsCTAs.length ? (
      <>
        {pageSettingsCTAs.map((cta, i) => (
          <PrimaryButtonLink key={i} className={styles.assetCTAButton} href={cta.href}>
            {cta.label}
          </PrimaryButtonLink>
        ))}
      </>
    ) : null;

  return (
    <Banner
      heading={heading}
      Asset={Asset}
      AssetCTA={AssetCTA}
      TextContent={TextContent}
      CTA={CTA}
      Table={Table}
      watermark={{
        variant: 'O',
        aspectRatio: 1 / fluidImage.localFile.childImageSharp.fluid.aspectRatio,
      }}
    />
  );
};

export const BannerBlock = React.memo(BannerBlockComponent);
