import { WPImageFluid } from 'shared/types/wp_localImage';
import { checkExists } from './exists';

interface WPQueryFluidImage {
  __typename: 'ImageSharpFluid';
  aspectRatio: number;
  src: string;
  srcSet: string;
  srcWebp: string | null;
  srcSetWebp: string | null;
  sizes: string;
}

type Props = {
  localFile: {
    childImageSharp: {
      fluid: WPQueryFluidImage | null;
    } | null;
  } | null;
};

/**
 *
 * WPGatsby seems to make all these properties nullable.
 * Workaround until we confirm correct behaviour
 *
 * TODO: Apply correct typing across rest of website
 */
export const createFluidImage = (image: Props): WPImageFluid => {
  const localFile = checkExists(image.localFile);
  const childImageSharp = checkExists(localFile.childImageSharp);
  const fluid = checkExists(childImageSharp.fluid);
  return {
    localFile: {
      childImageSharp: {
        fluid: {
          ...fluid,
          srcSetWebp: fluid.srcSetWebp ? fluid.srcSetWebp : undefined,
          srcWebp: fluid.srcWebp ? fluid.srcWebp : undefined,
        },
      },
    },
  };
};
