import classNames from 'classnames';
import * as React from 'react';
import { TextPriority6, TextRegularAlt } from 'ui/components/atoms/typography/typography';
import { Container } from 'ui/components/organisms/container/container';
import styles from './table.module.css';

type TableProps = {
  headings: string[];
  rows: string[][];
  columns: 1 | 2 | 3 | 4;
};

const TableHead = ({ row }: { row: string[] }) => (
  <thead>
    <tr>
      {row.map((cell, i) => (
        <th key={i} className={styles.th}>
          <TextPriority6>{cell}</TextPriority6>
        </th>
      ))}
    </tr>
  </thead>
);

const TableBody = ({ rows }: { rows: string[][] }) => (
  <tbody>
    {rows.map((row, i) => (
      <tr key={i} className={styles.tr}>
        {row.map((x, j) => (
          <td key={j} className={styles.td}>
            <TextRegularAlt tagName="span">{x}</TextRegularAlt>
          </td>
        ))}
      </tr>
    ))}
  </tbody>
);

export const Table = ({ headings, rows, columns }: TableProps) => {
  return (
    <Container>
      <div className={styles.scroller}>
        <div className={styles.tableContainer}>
          <table
            className={classNames(styles.table, {
              [styles.td50]: columns === 2,
              [styles.td33]: columns === 3,
              [styles.td25]: columns === 4,
            })}>
            <TableHead row={headings} />
            <TableBody rows={rows} />
          </table>
        </div>
      </div>
    </Container>
  );
};
