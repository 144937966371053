import * as React from 'react';
import { Container } from 'ui/components/organisms/container/container';
import { BackgroundColor, Section } from 'ui/components/molecules/section/section';
import { Col, Row } from 'ui/components/organisms/grid/grid';
import styles from './faq.module.css';
import { Spacing, TextPriority3, TextSub2 } from 'ui/components/atoms/typography/typography';
import { Accordion } from '../accordion/accordion';
import { WYSIWYG } from '../wysiwyg/wysiwyg';

type FaqProps = {
  className?: string;
  backgroundColor?: BackgroundColor;
  preheading?: string;
  heading: string;
  questions: {
    question: string;
    answer: string;
  }[];
};

export const FaqComponent = ({
  backgroundColor = 'white',
  preheading,
  heading,
  questions,
  className,
}: FaqProps) => (
  <Section backgroundColor={backgroundColor} className={className}>
    <Container>
      <div className={styles.faq}>
        <Row center="sm">
          <Col sm={12}>
            {preheading && (
              <TextSub2 tagName="div" bottomSpacing={Spacing.SPACING_8}>
                {preheading}
              </TextSub2>
            )}
            <TextPriority3 bottomSpacing={Spacing.SPACING_40}>{heading}</TextPriority3>
          </Col>
        </Row>
        {questions.map((x, i) => (
          <Accordion key={i} heading={x.question} topBorder={i === 0}>
            <WYSIWYG html={x.answer} />
          </Accordion>
        ))}
      </div>
    </Container>
  </Section>
);

export const Faq = React.memo(FaqComponent);
