import * as React from 'react';
import {
  CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_blocks_WpPage_Acftemplatecampaign_Campaign_Blocks_Assetblock,
  CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_blocks_WpPage_Acftemplatecampaign_Campaign_Blocks_Assetblock_asset,
} from 'templates/gql_types/CampaignPageQuery';
import { Spacing, TextPriority3, TextSmall } from 'ui/components/atoms/typography/typography';
import { Section } from 'ui/components/molecules/section/section';
import { Vimeo } from 'ui/components/molecules/vimeo/vimeo';
import { Container } from 'ui/components/organisms/container/container';
import { Col, Row } from 'ui/components/organisms/grid/grid';
import { exists } from 'util/exists';
import { createFluidImage } from 'util/createFluidImage';
import { WPImageFluid } from 'shared/types/wp_localImage';
import styles from './block_asset.module.css';
import { Picture } from 'ui/components/atoms/picture/picture';

const AssetImage = ({ image, caption }: { image: WPImageFluid; caption?: string }) => {
  return (
    <div className={styles.assetImage}>
      <Picture type="fluid" fluid={image.localFile.childImageSharp.fluid} placeholder="285x190" />
      {caption && (
        <TextSmall tagName="div" className={styles.caption}>
          {caption}
        </TextSmall>
      )}
    </div>
  );
};

const getAsset = (
  asset: CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_blocks_WpPage_Acftemplatecampaign_Campaign_Blocks_Assetblock_asset,
) => {
  const assetBlock =
    asset.__typename === 'WpPage_Acftemplatecampaign_Campaign_Blocks_Assetblock_Asset_Image' ? (
      <AssetImage
        image={createFluidImage(asset.image)}
        caption={asset.caption ? asset.caption : undefined}
      />
    ) : (
      <Vimeo id={asset.vimeoid} caption={asset.caption ? asset.caption : undefined} />
    );
  return () => assetBlock;
};

export const AssetBlock = ({
  block,
  className,
}: {
  block: CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_blocks_WpPage_Acftemplatecampaign_Campaign_Blocks_Assetblock;
  className?: string;
}) => {
  const Asset = block.asset ? block.asset.filter(exists).map(getAsset)[0] : undefined;
  return (
    <Section backgroundColor="navy" className={className}>
      <Container>
        <Row center="sm">
          <Col sm={12}>
            <TextPriority3 tagName="h2" bottomSpacing={Spacing.SPACING_24} color="white">
              {block.title}
            </TextPriority3>
          </Col>
        </Row>
        {Asset && <Asset />}
      </Container>
    </Section>
  );
};
