import * as React from 'react';
import { PageTemplateProps } from 'shared/types/pages';
import { CampaignPageQuery_wpPage } from 'templates/gql_types/CampaignPageQuery';
import { createBlocks } from './campaign_mapper';
import styles from './campaign.module.css';
import { checkExists, exists } from 'util/exists';
import { getIsCampaignMode } from 'ui/components/organisms/layout/layout';
import { createRestrictedModal } from 'ui/components/molecules/modal/modal';
import { Main } from 'ui/components/organisms/main/main';
import { JsonLD } from 'ui/components/molecules/json_ld/json_ld';
import { CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_blocks_WpPage_Acftemplatecampaign_Campaign_Blocks_Accordion as AccordionBlockType } from 'templates/gql_types/CampaignPageQuery';

export const Campaign = ({ data }: PageTemplateProps<CampaignPageQuery_wpPage>) => {
  const { title: pageTitle } = data.wpPage;
  const { breadcrumbs } = data.wpPage.seo;
  // eslint-disable-next-line
  const { blocks, pageSettings } = data.wpPage.acfTemplateCampaign!.campaign;

  // 'hiddenMenuMode === true' is for SEM pages
  // Mobile only. On Desktop, this is rendered within <Header />
  const isHiddenMenuMode = pageSettings ? getIsCampaignMode(pageSettings.navigation) : false;
  const pageSettingsCTAs =
    isHiddenMenuMode && pageSettings.ctas ? pageSettings.ctas.filter(exists) : [];

  const Blocks = createBlocks(blocks, styles.assetBlock, pageSettingsCTAs, pageTitle);
  const { RestrictedModal, store } = createRestrictedModal({
    acfSharedPage: data.wpPage.acfSharedPage,
  });

  // JSON-LD
  const accordions = blocks
    .filter(
      (block): block is AccordionBlockType =>
        block.__typename === 'WpPage_Acftemplatecampaign_Campaign_Blocks_Accordion',
    )
    .map(accordion =>
      accordion.accordionItems.map(x =>
        x.__typename ===
        'WpPage_Acftemplatecampaign_Campaign_Blocks_Accordion_AccordionItems_Relational'
          ? {
              question: checkExists(x.relational.acf?.faq?.question),
              answer: checkExists(x.relational.acf?.faq?.answer),
            }
          : x,
      ),
    );

  const accordion = accordions ? accordions[accordions.length - 1] : undefined;

  return (
    <>
      <Main className={styles.main} isBlurred={store.isVisible}>
        <JsonLD
          title={pageTitle}
          acfSharedPage={data.wpPage.acfSharedPage}
          breadcrumbs={breadcrumbs}
          faqs={accordion && accordion.length > 0 ? accordion : undefined}
        />
        {Blocks.map((x, i) => (
          <React.Fragment key={i}>{x}</React.Fragment>
        ))}
      </Main>
      <RestrictedModal />
    </>
  );
};
