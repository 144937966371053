import * as React from 'react';
import {
  CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_blocks_WpPage_Acftemplatecampaign_Campaign_Blocks_Grid,
  CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_blocks_WpPage_Acftemplatecampaign_Campaign_Blocks_Grid_gridCta,
} from 'templates/gql_types/CampaignPageQuery';
import { Featured } from 'ui/components/molecules/featured/featured';
import { BackgroundColor } from 'ui/components/molecules/section/section';
import { createFluidImage } from 'util/createFluidImage';
import { exists } from 'util/exists';

const getGridCta = (
  gridCta: CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_blocks_WpPage_Acftemplatecampaign_Campaign_Blocks_Grid_gridCta,
): {
  label: string;
  url: string;
} => {
  return gridCta.__typename === 'WpPage_Acftemplatecampaign_Campaign_Blocks_Grid_GridCta_CustomLink'
    ? {
        label: gridCta.label,
        url: gridCta.urlCustom,
      }
    : {
        label: gridCta.label,
        url: gridCta.url.uri,
      };
};

export const GridBlock = ({
  block,
  backgroundColor,
}: {
  block: CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_blocks_WpPage_Acftemplatecampaign_Campaign_Blocks_Grid;
  backgroundColor: BackgroundColor;
}) => {
  const cta = block.gridCta ? block.gridCta.filter(exists).map(getGridCta) : undefined;

  return (
    <Featured
      backgroundColor={backgroundColor}
      heading={block.heading}
      subheading={block.preheading ? block.preheading : undefined}
      items={block.gridItems.map(x => ({
        ...x,
        subheading: x.subheading ? x.subheading : undefined,
        link: x.link ? x.link : undefined,
        image: createFluidImage(x.image),
      }))}
      cta={cta ? cta[0] : undefined}
    />
  );
};
