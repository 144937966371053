import * as React from 'react';
import styles from './paginate.module.css';

export const Paginate = ({ perc }: { perc: number }) => (
  <div className={styles.container}>
    <div className={styles.tracker}>
      <div className={styles.bar} style={{ transform: `translateX(${perc}%)` }} />
    </div>
  </div>
);
