import React, { useReducer } from 'react';
import classNames from 'classnames';
import styles from './accordion.module.css';
import { TextPriority6 } from 'ui/components/atoms/typography/typography';

type AccordionState = {
  isActive: boolean;
};

type AccordionActions = {
  type: 'TOGGLE';
};

const initialState: AccordionState = { isActive: false };

function reducer(state: AccordionState, action: AccordionActions) {
  switch (action.type) {
    case 'TOGGLE':
      return { isActive: !state.isActive };
    default:
      throw new Error();
  }
}

type AccordionProps = {
  heading: string;
  children: any;
  topBorder?: boolean;
};

export const Accordion = ({ children, heading, topBorder }: AccordionProps) => {
  const [{ isActive }, dispatch] = useReducer(reducer, initialState);
  return (
    <div className={classNames(styles.accordion, { [styles.topBorder]: topBorder })}>
      <div className={styles.heading} onClick={() => dispatch({ type: 'TOGGLE' })}>
        <TextPriority6>{heading}</TextPriority6>
        <span className={classNames(styles.arrow, { [styles.arrowIsActive]: isActive })} />
      </div>
      <div
        className={classNames(styles.contentContainer, {
          [styles.contentContainerIsActive]: isActive,
        })}>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};
