import * as React from 'react';
import { BlocksTableEnum } from 'shared/gql_types/globalTypes';
import {
  CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_blocks_WpPage_Acftemplatecampaign_Campaign_Blocks_Table,
  CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_blocks_WpPage_Acftemplatecampaign_Campaign_Blocks_Table_tableContents,
  CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_blocks_WpPage_Acftemplatecampaign_Campaign_Blocks_Table_tableCta,
} from 'templates/gql_types/CampaignPageQuery';
import { PrimaryButtonLink } from 'ui/components/atoms/link/link';
import {
  Spacing,
  TextPriority3,
  TextRegular,
  TextSub2,
} from 'ui/components/atoms/typography/typography';
import { Table } from 'ui/components/molecules/table/table';
import { Container } from 'ui/components/organisms/container/container';
import { Col, Row } from 'ui/components/organisms/grid/grid';
import { exists } from 'util/exists';
import styles from './block_table.module.css';

type TableRow =
  | {
      type: 'Heading';
      data: string[];
    }
  | {
      type: 'Regular';
      data: string[];
    };

const getContents = (
  contents: CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_blocks_WpPage_Acftemplatecampaign_Campaign_Blocks_Table_tableContents,
  length: number,
): TableRow => {
  switch (contents.__typename) {
    case 'WpPage_Acftemplatecampaign_Campaign_Blocks_Table_TableContents_Tablehead':
      return {
        type: 'Heading',
        data: [
          contents.thColumn1 ?? '',
          contents.thColumn2 ?? '',
          contents.thColumn3 ?? '',
          contents.thColumn4 ?? '',
        ].slice(0, length),
      };
    case 'WpPage_Acftemplatecampaign_Campaign_Blocks_Table_TableContents_Tablerow':
      return {
        type: 'Regular',
        data: [
          contents.tdColumn1 ?? '',
          contents.tdColumn2 ?? '',
          contents.tdColumn3 ?? '',
          contents.tdColumn4 ?? '',
        ].slice(0, length),
      };
  }
};

const getRows = (
  columns: BlocksTableEnum,
  contents: CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_blocks_WpPage_Acftemplatecampaign_Campaign_Blocks_Table_tableContents,
) => {
  switch (columns) {
    case BlocksTableEnum.ONE:
      return getContents(contents, 1);
    case BlocksTableEnum.TWO:
      return getContents(contents, 2);
    case BlocksTableEnum.THREE:
      return getContents(contents, 3);
    case BlocksTableEnum.FOUR:
      return getContents(contents, 4);
  }
};

const getTableCta = (
  tableCta: CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_blocks_WpPage_Acftemplatecampaign_Campaign_Blocks_Table_tableCta,
): {
  label: string;
  url: string;
} => {
  return tableCta.__typename ===
    'WpPage_Acftemplatecampaign_Campaign_Blocks_Table_TableCta_CustomLink'
    ? {
        label: tableCta.label,
        url: tableCta.urlCustom,
      }
    : {
        label: tableCta.label,
        url: tableCta.url.uri,
      };
};

const TableBlockComponent = ({
  block,
}: {
  block: CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_blocks_WpPage_Acftemplatecampaign_Campaign_Blocks_Table;
}) => {
  const { tableContents, tableheading, tablepreheading, tableDisclaimer, tableCta } = block;
  const columnSize = block.tableLayout;
  const contents = tableContents
    ? tableContents.filter(exists).map(x => getRows(columnSize, x))
    : [];
  const heading = tableheading ? tableheading : undefined;
  const preheading = tablepreheading ? tablepreheading : undefined;

  const [thead] = contents.filter(x => x.type === 'Heading');
  const tbody = contents.filter(x => x.type === 'Regular').map(x => x.data);
  const cta = tableCta ? tableCta.filter(exists).map(getTableCta) : undefined;

  return (
    <>
      <Container>
        <Row center="sm">
          <Col sm={12}>
            {preheading && (
              <TextSub2 tagName="div" bottomSpacing={Spacing.SPACING_8}>
                {preheading}
              </TextSub2>
            )}
            {heading && (
              <TextPriority3 tagName="h2" bottomSpacing={Spacing.SPACING_40}>
                {heading}
              </TextPriority3>
            )}
          </Col>
        </Row>
      </Container>
      <Table
        headings={thead.data}
        rows={tbody}
        columns={
          columnSize === BlocksTableEnum.TWO
            ? 2
            : columnSize === BlocksTableEnum.THREE
            ? 3
            : columnSize === BlocksTableEnum.FOUR
            ? 4
            : 1
        }
      />
      <Container>
        {tableDisclaimer && (
          <Row className={styles.disclaimer}>
            <Col sm={12}>
              <TextRegular>{tableDisclaimer}</TextRegular>
            </Col>
          </Row>
        )}
        {cta && (
          <Row center="sm" className={styles.cta}>
            <Col>
              <PrimaryButtonLink className={styles.button} href={cta[0].url}>
                {cta[0].label}
              </PrimaryButtonLink>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export const TableBlock = React.memo(TableBlockComponent);
