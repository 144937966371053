import * as React from 'react';
import { Spacing, TextPriority2 } from 'ui/components/atoms/typography/typography';
import { WatermarksBanner } from 'ui/components/atoms/watermarks/watermarksBanner';
import { Container } from 'ui/components/organisms/container/container';
import { Col, Row } from 'ui/components/organisms/grid/grid';
import styles from './banner.module.css';

type BannerProps = {
  heading: string;
  TextContent?: React.ComponentType;
  Asset: React.ComponentType;
  AssetCTA?: React.ComponentType;
  CTA?: React.ComponentType;
  Table?: React.ComponentType;
  watermark?: {
    variant: 'P' | 'O';
    aspectRatio: number;
  };
};

export const Banner = ({
  heading,
  TextContent,
  CTA,
  Asset,
  AssetCTA,
  watermark,
  Table,
}: BannerProps) => {
  return (
    <div className={styles.banner}>
      <div className={styles.bannerInner}>
        {watermark && (
          <WatermarksBanner variant={watermark.variant} aspectRatio={watermark.aspectRatio} />
        )}

        <Container>
          <Row middle="md">
            <Col sm={12} lg={10} lgOffset={1}>
              <div className={styles.grid}>
                <div className={styles.heading}>
                  <TextPriority2 tagName="h1" bottomSpacing={Spacing.SPACING_16}>
                    {heading}
                  </TextPriority2>
                </div>
                <div className={styles.assetContainer}>
                  <Asset />
                  {AssetCTA && (
                    <div className={styles.assetCTA}>
                      <AssetCTA />
                    </div>
                  )}
                </div>
                <div className={styles.contentContainer}>
                  {TextContent && <TextContent />}
                  {Table && (
                    <div className={styles.tableContainer}>
                      <Table />
                    </div>
                  )}
                  {CTA && (
                    <div className={styles.ctaContainer}>
                      <CTA />
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
