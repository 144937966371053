import * as React from 'react';
import { CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_blocks_WpPage_Acftemplatecampaign_Campaign_Blocks_Sideimageblock } from 'templates/gql_types/CampaignPageQuery';
import { Spacing, TextPriority3, TextSub2 } from 'ui/components/atoms/typography/typography';
import { Container } from 'ui/components/organisms/container/container';
import { Col, Row } from 'ui/components/organisms/grid/grid';
import { SideImage } from 'ui/components/organisms/side_image/side_image';
import { createFluidImage } from 'util/createFluidImage';

export const SideImageBlock = ({
  block,
}: {
  block: CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_blocks_WpPage_Acftemplatecampaign_Campaign_Blocks_Sideimageblock;
}) => {
  const { preheading, heading, sideImages } = block;
  return (
    <>
      <Container>
        <Row center="sm">
          <Col sm={12}>
            {preheading && (
              <TextSub2 tagName="div" bottomSpacing={Spacing.SPACING_8}>
                {preheading}
              </TextSub2>
            )}
            <TextPriority3 tagName="h2" bottomSpacing={Spacing.SPACING_24}>
              {heading}
            </TextPriority3>
          </Col>
        </Row>
      </Container>
      {sideImages.map((x, i) => {
        const side = i % 2 == 0 ? 'LEFT' : 'RIGHT';
        return (
          <SideImage
            key={i}
            wysiwyg={x.wysiwyg}
            image={createFluidImage(x.image)}
            side={side}
            isLast={i === sideImages.length - 1}
          />
        );
      })}
    </>
  );
};
