import * as React from 'react';
import {
  CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_blocks_WpPage_Acftemplatecampaign_Campaign_Blocks_Getintouch,
  CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_blocks_WpPage_Acftemplatecampaign_Campaign_Blocks_Getintouch_getintouchCta,
} from 'templates/gql_types/CampaignPageQuery';
import { GetInTouch, defaultHeading } from 'ui/components/molecules/get_in_touch/get_in_touch';
import { exists } from 'util/exists';

const getCTA = (
  cta: CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_blocks_WpPage_Acftemplatecampaign_Campaign_Blocks_Getintouch_getintouchCta,
): {
  label: string;
  href: string;
  target: '_self' | '_blank';
} => {
  return cta.__typename ===
    'WpPage_Acftemplatecampaign_Campaign_Blocks_Getintouch_GetintouchCta_CustomLink'
    ? {
        label: cta.label,
        href: cta.urlCustom,
        target: '_blank',
      }
    : {
        label: cta.label,
        href: cta.url.uri,
        target: '_self',
      };
};

export const GetInTouchBlock = ({
  block,
  pageTitle,
}: {
  block: CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_blocks_WpPage_Acftemplatecampaign_Campaign_Blocks_Getintouch;
  pageTitle: string;
}) => {
  const {
    getintouchHeading: heading,
    getintouchDescription: description,
    getintouchPreheading: preheading,
    getintouchCta,
  } = block;

  const cta = getintouchCta ? getintouchCta.filter(exists).map(getCTA) : undefined;

  return (
    <GetInTouch
      heading={heading ?? defaultHeading(pageTitle)}
      description={description}
      preheading={preheading}
      primaryCta={cta ? cta[0] : undefined}
    />
  );
};
