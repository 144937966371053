import * as React from 'react';
import {
  CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_blocks,
  CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_pageSettings_ctas,
} from 'templates/gql_types/CampaignPageQuery';
import { BackgroundColor, Section } from 'ui/components/molecules/section/section';
import { WYSIWYG } from 'ui/components/molecules/wysiwyg/wysiwyg';
import { Carousel } from 'ui/components/organisms/carousel/carousel';
import { Container } from 'ui/components/organisms/container/container';
import { createFluidImage } from 'util/createFluidImage';
import { AccordionBlock } from './blocks/block_accordion';
import { AssetBlock } from './blocks/block_asset';
import { BannerBlock } from './blocks/block_banner';
import { GetInTouchBlock } from './blocks/block_getintouch';
import { GridBlock } from './blocks/block_grid';
import { SideImageBlock } from './blocks/block_sideimage';
import { TableBlock } from './blocks/block_table';

const withSection = ({
  Block,
  backgroundColor = 'white',
}: {
  Block: React.ComponentType;
  backgroundColor?: BackgroundColor;
}) => {
  return (
    <Section backgroundColor={backgroundColor}>
      <Block />
    </Section>
  );
};

const createBlock = ({
  block,
  index,
  assetBlockClassName,
  pageSettingsCTAs,
  pageTitle,
}: {
  block: CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_blocks;
  index: number;
  assetBlockClassName: string;
  pageSettingsCTAs: CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_pageSettings_ctas[];
  pageTitle: string;
}) => {
  const backgroundColor: BackgroundColor =
    index % 4 === 1 ? 'grey' : index % 4 === 2 ? 'pale' : index % 4 === 3 ? 'pale40' : 'white';
  switch (block.__typename) {
    case 'WpPage_Acftemplatecampaign_Campaign_Blocks_Accordion':
      return <AccordionBlock block={block} backgroundColor={backgroundColor} />;
    case 'WpPage_Acftemplatecampaign_Campaign_Blocks_Assetblock':
      return <AssetBlock block={block} className={assetBlockClassName} />;
    case 'WpPage_Acftemplatecampaign_Campaign_Blocks_Banner':
      return <BannerBlock block={block} pageSettingsCTAs={pageSettingsCTAs} />;
    case 'WpPage_Acftemplatecampaign_Campaign_Blocks_Carousel':
      return withSection({
        backgroundColor,
        Block: () => (
          <Carousel
            caption={block.carouselCaption ? block.carouselCaption : undefined}
            items={block.carouselItems.map(x => ({ image: createFluidImage(x.image) }))}
          />
        ),
      });
    case 'WpPage_Acftemplatecampaign_Campaign_Blocks_Grid':
      return <GridBlock block={block} backgroundColor={backgroundColor} />;
    case 'WpPage_Acftemplatecampaign_Campaign_Blocks_Sideimageblock':
      return withSection({
        backgroundColor,
        Block: () => <SideImageBlock block={block} />,
      });
    case 'WpPage_Acftemplatecampaign_Campaign_Blocks_Table':
      return withSection({
        backgroundColor,
        Block: () => <TableBlock block={block} />,
      });
    case 'WpPage_Acftemplatecampaign_Campaign_Blocks_Wysiwygblock':
      return withSection({
        backgroundColor,
        Block: () => (
          <Container>
            <WYSIWYG html={block.wysiwyg} />
          </Container>
        ),
      });
    case 'WpPage_Acftemplatecampaign_Campaign_Blocks_Getintouch':
      return <GetInTouchBlock block={block} pageTitle={pageTitle} />;
    default:
      throw new Error('createBlock not found');
  }
};

export const createBlocks = (
  blocks: CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_blocks[],
  assetBlockClassName: string,
  pageSettingsCTAs: CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_pageSettings_ctas[],
  pageTitle: string,
) =>
  blocks.map((block, index) =>
    createBlock({
      block,
      index,
      assetBlockClassName,
      pageSettingsCTAs,
      pageTitle,
    }),
  );
