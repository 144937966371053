import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { WPImageFluid } from 'shared/types/wp_localImage';
import { Picture } from 'ui/components/atoms/picture/picture';
import { TextSub2 } from 'ui/components/atoms/typography/typography';
import { Container } from 'ui/components/organisms/container/container';
import { Col, Row } from 'ui/components/organisms/grid/grid';
import { Paginate } from 'ui/components/atoms/paginate/paginate';
import { useEventListener } from 'hooks/useEventListener';
import Arrow from 'ui/components/atoms/icons/arrow.inline.svg';
import styles from './carousel.module.css';

type CarouselProps = {
  className?: string;
  caption?: string;
  items: {
    image: WPImageFluid | null;
  }[];
};

const CarouselSingle = ({ items, caption }: CarouselProps) => {
  const [item] = items;
  return (
    <Container>
      <Row>
        <Col sm={12}>
          <Picture
            type="fluid"
            fluid={item.image?.localFile.childImageSharp.fluid}
            placeholder="285x190"
          />
        </Col>
      </Row>
      <Row between="md" middle="md">
        <Col sm={12} md={6}>
          {caption && <Caption caption={caption} className={styles.caption} />}
        </Col>
      </Row>
    </Container>
  );
};

const CarouselDouble = ({ items, caption }: CarouselProps) => {
  const [left, right] = items;
  return (
    <Container>
      <Row>
        <Col sm={12} lg={6}>
          <Picture
            type="fluid"
            fluid={left.image?.localFile.childImageSharp.fluid}
            placeholder="285x190"
          />
        </Col>
        <Col sm={12} lg={6} className={styles.offset}>
          <Picture
            type="fluid"
            fluid={right.image?.localFile.childImageSharp.fluid}
            placeholder="285x190"
          />
        </Col>
      </Row>
      <Row between="md" middle="md">
        <Col sm={12} md={6}>
          {caption && <Caption caption={caption} className={styles.caption} />}
        </Col>
      </Row>
    </Container>
  );
};

type CarouselScrollableState = {
  x: number;
  mouseX: number;
  isMouseDown: boolean;
};

type CarouselArrowDirections = 'FORWARDS' | 'BACKWARDS';

const CarouselScrollable = ({ items, caption }: CarouselProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [percentage, setPercentage] = useState(0);
  const [position, setPosition] = useState<CarouselScrollableState>({
    x: 0,
    mouseX: 0,
    isMouseDown: false,
  });

  const arrowClickHandler = (dir: CarouselArrowDirections) => {
    if (ref.current == null) {
      return;
    }
    const x = (ref.current.scrollWidth - document.body.clientWidth) / 4;
    return ref.current.scrollTo({
      left: dir === 'FORWARDS' ? ref.current.scrollLeft + x : ref.current.scrollLeft - x,
      behavior: 'smooth',
    });
  };

  const scrollHandler = () => {
    if (ref.current == null) {
      return;
    }
    const maxX = ref.current.scrollWidth - document.body.clientWidth;
    const x = ref.current.scrollLeft / maxX;
    const percentage = Math.floor(x * 100);

    setPercentage(percentage);
  };

  const mouseDownHandler = (e: Event) => {
    const event = e as MouseEvent;
    setPosition({
      ...position,
      isMouseDown: true,
      x: ref.current ? ref.current.scrollLeft : 0,
      mouseX: event.clientX,
    });
  };

  const mouseMoveHandler = (e: Event) => {
    if (position.isMouseDown && ref.current) {
      const event = e as MouseEvent;
      const dx = event.clientX - position.mouseX;
      ref.current.scrollLeft = position.x - dx;
    }
  };

  const mouseUpHandler = () => {
    setPosition({
      ...position,
      isMouseDown: false,
    });
  };

  useEventListener('scroll', scrollHandler, ref);
  useEventListener('mousedown', mouseDownHandler);
  useEventListener('mousemove', mouseMoveHandler);
  useEventListener('mouseup', mouseUpHandler);

  return (
    <div
      className={classNames(styles.scrollerContainer, {
        [styles.grabbing]: position.isMouseDown,
      })}>
      <div ref={ref} className={styles.scroller}>
        <div className={styles.wrapper}>
          <Row className={styles.carouselRow}>
            {items.map((item, index) => {
              return (
                <Col sm={11} md={8} xl={6} key={index}>
                  <Picture
                    type="fluid"
                    fluid={item.image?.localFile.childImageSharp.fluid}
                    placeholder="285x190"
                    className={styles.picture}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
      <Container className={styles.caption}>
        <Row between="md" middle="md">
          <Col sm={12} md={6}>
            {caption && <Caption caption={caption} />}
          </Col>
          <Col sm={12} md={6} className={styles.progressContainer}>
            <div className={styles.progress}>
              <Paginate perc={percentage} />
              <div className={styles.arrows}>
                <div className={styles.arrowLeft} onClick={() => arrowClickHandler('BACKWARDS')}>
                  <Arrow />
                </div>
                <div className={styles.arrowRight} onClick={() => arrowClickHandler('FORWARDS')}>
                  <Arrow />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

type CarouselTypes = 'SINGLE' | 'DOUBLE' | 'SCROLLABLE';

const createCarousel = (type: CarouselTypes) => {
  switch (type) {
    case 'SINGLE':
      return CarouselSingle;
    case 'DOUBLE':
      return CarouselDouble;
    default:
      return CarouselScrollable;
  }
};

function getCarouselType(length: number) {
  switch (length) {
    case 1:
      return 'SINGLE';
    case 2:
      return 'DOUBLE';
    default:
      return 'SCROLLABLE';
  }
}

function Caption({ caption, className }: { caption: string; className?: string }) {
  return (
    <div className={className}>
      <TextSub2>{caption}</TextSub2>
    </div>
  );
}

const CarouselComponent = ({ caption, items, className }: CarouselProps) => {
  if (items.length == null) {
    return null;
  }
  const CarouselComponent = createCarousel(getCarouselType(items.length));
  return (
    <div className={classNames(styles.container, className)}>
      <CarouselComponent items={items} caption={caption} />
    </div>
  );
};

export const Carousel = React.memo(CarouselComponent);
